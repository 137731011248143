<template>
<div class="convention-hall page">
<div class="nav-bar van-nav-bar van-hairline--bottom">
<div class="van-nav-bar__content"><div class="van-nav-bar__title van-ellipsis">Xổ Số May Mắn</div></div></div>
  <div class="activity-info">
   
    <div class="wheel-container">
      <div class="rotate">
        <img :src="wheelImage" class="dish" :style="{ transform: wheelTransform, transition: 'transform 5s ease-out' }" />
        <img :src="pointerImage" class="pointer" @click="spinWheel" />
      </div>
    </div>
    <div class="result-info">
      <p>Số lần rút thăm: {{ spinCount }}</p>
      <p>Giải thưởng trúng thưởng: {{ prize }}</p>
      <p>Thời gian trúng thưởng: {{ prizeTime }}</p>
      <p>Sau khi quay thưởng trúng giải vui lòng chụp ảnh màn hình gửi đến CSKH để nhận quà!</p>
    </div>
  </div>
  <div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        'Thuốc xịt khử mùi',
        'Kẹp giấy',
        'Bút bi',
        'Bóp đựng tiền',
        'Kẹo bạc hà',
        'Thước kẻ',
        'Cặp tóc',
        'Lược chải đầu'
      ],
      spinCount: 0,
      prize: 'Chờ rút thăm',
      prizeTime: 'Chờ rút thăm',
      wheelImage: 'https://yku888uyby.xyz/static/img_m/1home.34fcc49.png',
      pointerImage: 'https://yku888uyby.xyz/static/img_m/2home.13104eb.png',
      wheelTransform: 'rotate(0deg)',
      isSpinning: false
    };
  },
  methods: {
    spinWheel() {
      if (this.isSpinning) return; // Prevent spinning while already spinning

      // Get current timestamp
      const currentTime = new Date().getTime();

      // Get IP address (you may need a more accurate way to get user's IP)
      const ipAddress = 'dummy-ip-address'; // Replace with actual IP address retrieval

      // Retrieve last spin timestamp for this IP from localStorage
      const lastSpinString = localStorage.getItem(`lastSpin_${ipAddress}`);
      let lastSpin = lastSpinString ? parseInt(lastSpinString, 10) : 0;

      // Check if enough time has passed (24 hours = 86400000 milliseconds)
      if (currentTime - lastSpin < 86400000) {
        alert('Bạn chỉ được quay một lần mỗi ngày. Vui lòng thử lại sau.');
        return;
      }

      // Allow spinning
      this.isSpinning = true;

      const randomAngle = Math.floor(Math.random() * 360) + 3600; // Spin at least 10 rounds
      this.wheelTransform = `rotate(${randomAngle}deg)`;

      setTimeout(() => {
        const prizeIndex = Math.floor(((randomAngle % 360) / 360) * this.items.length);
        this.prize = this.items[prizeIndex];
        this.prizeTime = new Date().toLocaleTimeString();
        this.spinCount += 1;
        this.isSpinning = false;

        // Update last spin timestamp in localStorage
        localStorage.setItem(`lastSpin_${ipAddress}`, currentTime.toString());
      }, 5000); // Match transition time
    }
  }
};
</script>

<style scoped>
.activity-info {
  text-align: center;
  font-family: Arial, sans-serif;
}

.wheel-container {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 20px auto;
}

.rotate {
  position: relative;
  width: 100%;
  height: 100%;
}

.dish {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: blink 2s infinite;
}

.pointer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.result-info {
  margin-top: 20px;
}

.result-info p {
  font-size: 16px;
  color: #333;
}

@keyframes blink {
  0% { box-shadow: 0 0 10px rgba(0, 0, 255, 0.5); }
  25% { box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); }
  50% { box-shadow: 0 0 10px rgba(255, 255, 0, 0.5); }
  75% { box-shadow: 0 0 10px rgba(255, 165, 0, 0.5); }
  100% { box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); }
}
</style>
