<template>
  <div class="container page">
    <van-nav-bar :title="videoInfo.vod_name" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    
    <div class="movie-video">
      <iframe
        width="100%"
        height="200"
        :src="youtubeEmbedUrl"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
    
    <div class="movie-content">
      <div class="movie-descript">
        <p>{{ videoInfo.vod_name }}</p>
        <span>{{ videoInfo.count }} vở kịch</span>
      </div>

      <div class="movie-body">
        <div class="movie-title">
          <div>
            <span>Khuyến nghị phổ biến</span>
          </div>
        </div>
        <div class="movie-list">
          <div
            class="movie-play-item"
            @click="playVideo(v.vod_play_url)"
            v-for="(v, key) in moreVideoInfo"
            :key="key"
          >
            <div>
              <img :src="v.vod_pic" alt="Video thumbnail">
              <div>
                <div class="van-count-down">{{ v.time }}</div>
              </div>
            </div>
            <div>
              <p>{{ v.vod_name }}</p>
              <span>{{ v.count }} vở kịch</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
import "videojs-contrib-hls";

export default {
  data() {
    return {
      videoInfo: {},
      moreVideoInfo: [],
      youtubeEmbedUrl: '', // URL nhúng video YouTube
      player: null,
    };
  },
  methods: {
    back() {
      this.$router.push({ path: 'Home' });
    },
    getVideoInfo() {
      this.$http({
        method: 'get',
        data: { id: this.$route.query.id },
        url: 'video_get_info'
      }).then(res => {
        this.videoInfo = res.data;
        this.youtubeEmbedUrl = this.getYoutubeEmbedUrl(this.videoInfo.vod_play_url);
        // Khởi tạo videojs player
        this.getVideo();
      });
    },
    playVideo(videoUrl) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' });
      } else {
        this.$router.push({ path: '?id=' + videoUrl });
        location.reload();
      }
    },
    getMoreVideoItem() {
      this.$http({
        method: 'get',
        url: 'video_get_more_item'
      }).then(res => {
        this.moreVideoInfo = res.data;
      });
    },
    getVideo() {
      // Khởi tạo videojs player
      this.player = videojs("my-video", {
        height: "200px",
        preload: "auto",
        controls: true,
        multipleArray: [0.75, 1, 1.5, 2],
      });
      this.player.src([{
        src: this.videoInfo.vod_play_url,
        type: "application/x-mpegURL"
      }]);
    },
    getYoutubeEmbedUrl(youtubeUrl) {
      // Chuyển đổi đường dẫn YouTube thành URL nhúng
      let videoId = this.getYoutubeVideoId(youtubeUrl);
      return `https://www.youtube.com/embed/${videoId}`;
    },
    getYoutubeVideoId(youtubeUrl) {
      // Lấy video ID từ đường dẫn YouTube
     let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      let match = youtubeUrl.match(regExp);
      if (match && match[2].length === 11) {
        return match[2];
      } else {
        return null;
      }
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          // Xử lý logic người dùng
          if (this.$store.getters.getBaseInfo.isplay == 1) {
            this.getVideoInfo();
            this.getMoreVideoItem();
            if (this.userInfo.money <= "0.00") {
              this.$toast("Vui lòng nạp tiền để xem video!");
              this.$router.push({ path: '/Home' });
            }
          } else {
            this.getVideoInfo();
            this.getMoreVideoItem();
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' });
    } else {
      this.getUserInfo();
    }
  },
  destroyed() {
    // Hủy bỏ videojs player
    if (this.player) {
      this.player.dispose();
    }
  }
};
</script>
<style scoped>
.video-js {
  width: 100%;
  /*height: 420px;*/
  font-size: 24px;
}
.movie-content{
  flex: 1;
  overflow-y: auto;
}
.movie-content .movie-descript{
  width: 100%;
  height: 140px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
}
.movie-content .movie-descript p{
  font-size: 30px;
  font-weight: 700;
  color: #000;
}
.movie-content .movie-descript span{
  color: #979799;
}
.movie-content .movie-body{
  width: calc(100% - 20px);
  margin: 0 auto;
}
::v-deep .movie-video .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
}
.movie-content .movie-body .movie-title{
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie-content .movie-body .movie-title>div:first-child {
  width: 200px;
}
.movie-content .movie-body .movie-title>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: 700;
  color: #000;
}
.movie-content .movie-body .movie-title>div:first-child span:before {
  content: "";
  display: block;
  width: 8px;
  height: 30px;
  background-color: #7e5678;
  border-radius: 25px;
  margin-right: 10px;
}
.movie-play-item{
  width: 100%;
  height: 200px;
  border-radius: 10px;
  position: relative;
  display: flex;
  background-color: #fff;
  margin-bottom: 20px;
}
.movie-play-item>div{
  height: 100%;
}
.movie-play-item>div:first-child {
  width: 200px;
  position: relative;
}
.movie-play-item>div:first-child>img{
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
}
.movie-play-item>div:first-child>div{
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background-color: rgba(0,0,0,.4);
  border-radius: 0 0 0 10px;
}
.movie-play-item>div:first-child>div .van-count-down {
  color: #fff;
  font-size: 25px;
}
.movie-play-item>div:nth-child(2) p{
  width: 500px;
  height: 60px;
  font-size: 30px;
  line-height: 32px;
  word-break: break-all;
  overflow: hidden;
  color: #000;
}
.movie-play-item>div:nth-child(2) span{
  color: #000;
}
.movie-play-item>div:nth-child(2) {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}
</style>